/* Color de letras y fondo */
.body-dona {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: #231602;
  color: #d4973c;
}

.container-dona {
  display: grid;
  grid-template-areas:
    'header'
    'nav'
    'main'
    'footer';
  grid-gap: 10px;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: #2d1b0f;
}

.header-dona {
  grid-area: header;
  color: white;
  padding: 10px;
  text-align: center;
  background: #444;
  border-radius: 10px;
}

.nav-dona {
  grid-area: nav;
  background: #444;
  padding: 10px;
  text-align: center;
  border-radius: 10px;
}

.nav-dona a {
  color: rgb(255, 255, 255);
  margin: 0 10px;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s;
}

.nav-dona a:hover {
  color: #d4973c;
}

.main-dona {
  grid-area: main;
  background: #2d1b0f;
  padding: 20px;
  border-radius: 10px;
}

.footer-dona {
  grid-area: footer;
  background: #9e5a5a;
  color: white;
  text-align: center;
  padding: 10px;
  border-radius: 10px;
}

.donaciones-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.grid-dona {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  justify-content: center;
  margin-top: 20px;
}

.grid-item {
  background: #333;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, box-shadow 0.3s;
}

.grid-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.grid-item img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.grid-item p {
  margin-top: 10px;
  color: #d4973c;
  font-size: 1.2em;
  font-weight: bold;
}

.container-dona h2 {
  margin-top: 10px;
  color: #d4973c;
  font-size: 1.5em;
  text-align: center;
}

.container-dona ul { /* Texto como donar */
  margin-top: 10px;
  color: #d4973c;
  font-size: 1.2em;
  list-style-type: none;
  padding-left: 0px;
  text-align: center;  
}

@media (max-width: 768px) {
  .container-dona ul {
    font-size: 0.3em; /* Ajusta el tamaño de la fuente para pantallas más pequeñas */
    padding-left: 10px; /* Agrega un poco de espacio a la izquierda para pantallas más pequeñas */
  }
}

.container-dona ul li {
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .grid-dona {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .grid-item:hover {
    transform: none;
    box-shadow: none;
  }

  .toggle-button {
    top: 30%;
    right: 5%;
    height: 25px;
    width: 50px;
    z-index: 99999;
  }

  .main-dona {
    padding: 10px;
  }

  .container-dona ul {
    font-size: 1em;
    padding-left: 10px;
  }

  .container-dona ul li {
    margin-bottom: 5px;
  }

  .pricing-table {
    flex-direction: column;
    align-items: center;
  }

  .pricing-table table {
    width: 100%;
    max-width: 100%;
    font-size: 0.9em;
  }
}

.grid-dona {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  justify-items: center;
  margin-top: 20px;
}

.grid-item {
  text-align: center;
  transition: transform 0.3s ease-in-out;
}

.grid-item img {
  max-width: 100%;
  height: auto;
}

.grid-item p {
  margin-top: 10px;
  font-size: 1em;
  color: #d4973c;
}

.grid-item.large {
  grid-column: span 2; /* Ocupa dos columnas */
}

.characters-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.character-column {
  margin: 0 20px;
  text-align: center;
}

.character-column p {
  margin: 10px 0;
  font-size: 1.2em;
  color: #d4973c;
}

@media (max-width: 768px) {
  .grid-dona {
    grid-template-columns: 1fr; /* Una columna en pantallas pequeñas */
  }

  .grid-item.large {
    grid-column: span 1; /* Ocupa una columna en pantallas pequeñas */
  }

  .grid-item {
    transition: none; /* Deshabilitar la transición en pantallas pequeñas */
  }
}

.auras-link {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #d4973c;
  color: #231602;
  text-decoration: none;
  font-weight: bold;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.auras-link:hover {
  background-color: #b47a33;
  color: #ffffff;
}

.pricing-table {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.pricing-table table {
  width: 100%;
  max-width: 800px;
  border-collapse: collapse;
  background-color: #333;
  color: #d4973c;
  border-radius: 10px;
  overflow: hidden;
}

.pricing-table th,
.pricing-table td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #444;
}

.pricing-table th {
  background-color: #444;
}

.pricing-table tr:hover {
  background-color: #555;
}

@media (max-width: 768px) {
  .main-dona {
    padding: 10px;
    text-align: center;
  }

  .container-dona ul {
    font-size: 1em;
    padding-left: 10px;

  }

  .container-dona ul li {
    margin-bottom: 5px;
  }

  .pricing-table {
    flex-direction: column;
    align-items: center;
  }

  .pricing-table table {
    width: 100%;
    max-width: 100%;
    font-size: 0.9em;
  }
}
.grid-item1 {
  background: #333;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, box-shadow 0.3s;
  color:#333;
}

