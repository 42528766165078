@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Staatliches&display=swap');
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
}
header{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 60px;
    z-index: 1000;
}
header .brand-logo{
    position: relative;
    background-color: #b39b32;
    font-size: 3.5em;
    font-family: 'Staatliches', cursive;
    color: #b39b32;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 5px;
    text-decoration: none;
    background-image: linear-gradient( -75deg,
    transparent 0,
    transparent 5%,
    rgba(255,255,255,0.5) 5%,
    rgba(255,255,255,0.5) 10%,
    transparent 10%,
    transparent 100%
    );
    background-size: 200% 100%;
    background-clip: text;
    -webkit-background-clip: text;
    transition: 1.2s all;
}
header .brand-logo:hover{
    background-position: -120%;
}

.toggle-button {
    position: fixed;
    top: 7%;
    right: 1%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 30px;
    width: 60px;
    background: transparent;
    border: none;
    cursor: pointer;
    box-sizing: border-box;
    transition: all .5s ease-in-out;
    z-index: 99999; /* Valor de z-index más alto */
}


.toggle-button__line{
    width: 30px;
    height: 2.5px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(255,101,47,.2);
    transition: all 0.5s ease-in-out;
}

/* Estilos para pantallas con un ancho máximo de 768px */
@media (max-width: 768px) {
    .toggle-button-container {
      position: relative; /* Contenedor con posición relativa */
    }
  
    .toggle-button {
      position: absolute; /* Posición absoluta en relación con el contenedor */
      right: 5%; /* Ajusta la posición derecha */
      height: 25px; /* Reduce la altura del botón */
      width: 50px; /* Reduce el ancho del botón */
      z-index: 99999; /* Valor de z-index más alto */
    }
  }
  
  
.nav{
    position: fixed;
    height: 100vh;
    width: 400px;
    top: 0%;
    right: 0%;
    transition: all 0.5s ease-in-out;
    padding: 70px 0;
    background-color: #fff;
    text-align: center;
    transform:translateX(100%);
    z-index: 500009;
    overflow: hidden;
}
.nav li{
    list-style: none;
    padding: 20px 0;   
}

.nav li:hover{
    background: #b39b32;
    color: #fff;
    cursor: pointer;
}
.nav li:hover a{
    color: #fff;
}
.nav li a{
    color: #b39b32;
    text-decoration: none;
    font-size: 20px;
}


.toggle.open .toggle-button__line{
    transform: translateX(-20px);
    background: transparent;
    box-shadow: none;
}
.toggle.open #effect-1{
    transform: rotate(45deg) translate(13px, 0px);
    background-color: #b39b32;
}
.toggle.open #effect-2{
    transform: rotate(-45deg) translate(13px, 0px);
    background-color: #b39b32;
}
.toggle.open .nav{
    transform: translateX(-0%);
    z-index: 999999;
}

.container{
    position: relative;
    width: 100%;
    min-height: 100vh;
    padding: 0 100px;
    background: #ce2222;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.container::before{
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 400px;
    height: 100%;
    background: #b39b32;
    transform-origin: top;
    transform: skewX(-25deg);
    z-index: 1;
}
.container video{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit:cover;
    opacity: 0.15;
    pointer-events: none;
}

/* Estilos para pantallas grandes */
.container .content-description {
    position: relative;
    max-width: 800px;
    z-index: 2;
}

.container .content-description h2 {
    color: #fff;
    font-size: 3.5em;
}

.container .content-description p { /* Texto beneficios */
    color: #fff;
    font-size: 1.6em;
    margin: 5px 0 25px;
}

.container .content-description a { /* Botones descarga */
    position: relative;
    display: inline-block;
    background: #fff;
    color: #b39b32;
    padding: 25px 25px;
    font-size: 1.1em;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
    text-decoration: none;
    transition: all 0.5s;
    
}



/* Large desktop */
@media (min-width: 1200px) { 
.container .content-description a {
color: #b39b32; 
height: 15%;
left: -30px;
margin-top: 20px;
font-size: medium;
 } 
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 979px) { 
    .container .content-description a {
    color: #b39b32; 
    height: 5%;
     } 
    }
/* Landscape phone to portrait tablet */
@media (max-width: 767px) { 
    .container .content-description a {
    color: #b39b32; 
    height: 5%;
     } 
    }
/* Landscape phones and down Config Botones descarga celu */
@media (max-width: 480px) { 
    .container .content-description a {
    color: #b39b32; 
    height: 5%;
    margin-top: 135px;
    left: -15px
     } 
    }



.container .content-description a:hover {
    background: #b39b32;
    color: #fff;
}

/* Estilos para dispositivos móviles */
@media screen and (max-width: 768px)  {
    .container .content-description {
        margin-top: 60%; /* Posiciona el texto un 30% más abajo */
    }

    .container .content-description h2 {
        font-size: 2.5em;
        margin-top: 5%; /* Ajusta el margen superior del título */
    }

    .container .content-description p {
        font-size: 1.2em;
        margin: 5% 0 15px; /* Ajusta los márgenes superior e inferior del párrafo */
    }

    .container .content-description a {
        padding: 15px 20px;
        font-size: 0.9em;
    }
}

.container .muscle-img{
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    z-index: 2;
    cursor: pointer;
}
.container .muscle-img img, /* Este cambia el PJ */
#img-switcher {
    position: fixed;
    bottom: 5%;
    height: 85%;
    right: -7%;
    z-index: 500;
    transition: all 1s ease-in-out;
    opacity: 0;
}

@media (max-width: 768px)  {  /* aca se maneja la imagen */
    .container .muscle-img img,
    #img-switcher {
        position: absolute; /* Fija la imagen en la ventana de visualización */
        top: 400px; /* Sube la imagen 300px desde la parte superior */
        left: -85px; /* Mueve completamente la imagen a la izquierda */
        height: 20%; /* Reduce la altura al 30% en pantallas pequeñas */
        transition: all 1s ease-in-out;
        opacity: 0; /* Mantén la imagen oculta */
        
    }
}


@media (max-width: 768px)  {  /* aca se maneja la imagen */
    .container .muscle-img img,
    #img-switcher {
        position: absolute; /* Fija la imagen en la ventana de visualización */
        top: 305px; /* Sube la imagen 300px desde la parte superior */
        left: 200px; /* Mueve completamente la imagen a la izquierda */
        height: 20%; /* Reduce la altura al 30% en pantallas pequeñas */
        transition: all 1s ease-in-out;
        opacity: 0; /* Mantén la imagen oculta */
    }
}

.image-effect-1{
    opacity: 1;
    visibility: visible;

}
   
.image-effect-2{
    opacity: 0;
    visibility: hidden;
}

.muscle-img.open .image-effect-1{
    opacity: 0;
    visibility: hidden;
}
.muscle-img.open .image-effect-2{
    opacity: 1;
    visibility: visible;
}
.content-effect-1{
    position: absolute;
    opacity: 1;
    visibility: visible;
    transition: all 1s ease-in-out;
    top: -150px; 
    left: 0;
}

@media screen and (max-width: 768px) {
    .content-effect-1 {
        top: 20px; /* Ajusta la posición en pantallas más pequeñas */
    }
}

@media screen and (max-width: 480px) {
    .content-effect-1 {
        top: 10px; /* Ajusta la posición en pantallas aún más pequeñas */
    }
}


.content-effect-2{
    opacity: 0;
    visibility: hidden;
    transition: all 1s ease-in-out;
}
.content-description.open .content-effect-1{
    opacity: 0;
    visibility: hidden;
}
.content-description.open .content-effect-2{
    opacity: 1;
    visibility: visible;
    
}

.social-logo{
    position: absolute;
    bottom: 40px;
    letter-spacing: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    /*z-index: 1000;*/
    font-size: 25px;
}
.social-logo li{
    list-style: none;
}
.social-logo li a{
    display: inline-block;
    margin-right: 15px;
    color: #fff;
    transition: all 0.5s;
    cursor: pointer;
}
.social-logo li a:hover{
    color: #b39b32;
}

@media (max-width: 991px){
    header{
        padding: 20px 40px;
    }
    .nav{
        width: 400px;
        z-index: 5000009;
    }
    .container{
        padding: 150px 40px 0;
        flex-direction: column;
        overflow: hidden;
        
    }
    .container::before{
        width: 300px;
        transform: skewX(-25deg) translateX(50%);
    }
    .container .content-description{
        max-width: 100%;
    }
    .container .content-description h2{
        font-size: 3em;
    }
    .container .content-description p{
        font-size: 1.4em;
    }
    .brand-logo{
        margin-top: 15px;
        font-size: 3em !important;
    }
    .social-logo{
        z-index: 1000;
        left: 6%;
    }
    .social-logo li a{
        margin: 10px 0px;
    }
}



@media (max-width: 810px){
    .container{
        padding: 120px 40px 0;
    }
    .container .content-description h2{
        font-size: 2.2em;
    }
    .container .content-description p{
        font-size: 1.2em;
    }
    .brand-logo{
        background-color: #fff !important;
    }
    .social-logo{
        bottom: 3.5%;
        flex-direction: column !important;
    }
}

@media (max-width: 500px){
    .toggle-button{
        top: 4.3%;
    }
    .nav{
        width: 220px;
        padding: 10px 0;
        z-index: 100000; /* Encima del menú */
        
    }
    .brand-logo{
        margin-top: 5px !important;
        font-size: 2em !important;  
    }
    
    .container .content-description a:hover{
        border: 1px solid #fff;        
    }
    .container .content-description h2{
        font-size: 2.4em;
    }
    .container .content-description p{
        font-size: 1em;
    }
}


/* Developed By Guido Favara */
body {
    margin: 0;
    font-family: Arial, sans-serif;
}

footer {
    background-color: #333;
    color: #fff;
    padding: 10px 0;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1000; /* esto pone el footer por encima */
}

.marquee {
    overflow: hidden;
    white-space: nowrap;
    animation: marquee 45s linear infinite;
}

.marquee:hover {
    animation-play-state: paused; /* Pausar la animación en hover */
}

.marquee span {
    display: inline-block;
    padding-left: 0; /* Cambiado de 100% a 0 */
    color: #fff; /* Color del texto */
}

@keyframes marquee {
    0% { transform: translateX(100%); }
    100% { transform: translateX(-100%); }
}

/* esto era exclusivo de dona.css*/

.body-aura {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .header-aura {  
  background-color: rgba(51, 51, 51, 0.3); /* Color con opacidad reducida header dona */
    color: #fff;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1; /* Asegura que el header esté por debajo del botón toggle */
  }
  /* Estilos para pantallas con un ancho máximo de 768px */
@media (max-width: 768px) {
    .header-aura {
      background-color: transparent; /* Elimina el background color en pantallas pequeñas */
    }
  }
  
  .header-aura h1 {
    margin: 0;
  }
  
  .nav-aura ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
  }
  
  .ul-aura li {
    margin-left: 10px;
  }
  
  .li-aura a {
    color: #fff;
    text-decoration: none;
  }
  
  
  .main-aura {  /* Altura Aura List */
    padding: 100px;
  }
  
  .aura-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    list-style-type: none;
    padding: 0;
  }
  
  .aura-list li {
    display: flex;
    flex-direction: column; /* Cambiado para mostrar el texto debajo de la imagen */
    align-items: center;
    width: calc(33.333% - 20px);
    margin-bottom: 20px;
    box-sizing: border-box;
    color: #d4973c; /* Color Lista Aura Texto */
  }
  
  .aura-list img {
    margin-bottom: 10px; /* Espacio entre la imagen y el texto */
    max-width: 100%; /* Ajusta el tamaño de la imagen según el contenedor */
    height: auto;
  }
  
  @media (max-width: 768px) {
    .aura-list li {
        width: calc(50% - 20px);
    }
  }
  
  @media (max-width: 480px) {
    .aura-list li {
        width: 100%;
    }
  
    .aura-list img {
      max-width: 100%; /* Ajusta el tamaño de la imagen según el contenedor */
      height: auto;
      margin-bottom: 10px; /* Espacio entre cada imagen */
    }
  }
  .footer-aura {
    text-align: center;
    padding: 0.1px;
    background-color: #333;
    color: #fff;
    position: fixed;
    bottom: 0;
    width: 100%;
    /*visibility: ;  Oculta el footer inicialmente */
  }
  
    /*nuevos */

    .image-container1 {
        position: relative;
        width: 200px; /* Ajusta el ancho según tus necesidades */
        height: 200px; /* Ajusta el alto según tus necesidades */
        overflow: hidden; /* Oculta las partes de la imagen que se salgan del contenedor */
    }
    
    .image-container1 img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        animation: slideAnimation 15s infinite; /* Ajusta la duración y el intervalo según sea necesario */
    }
    
    @keyframes slideAnimation {
        0% { opacity: 1; }
        20% { opacity: 0; }
        25% { opacity: 0; }
        45% { opacity: 1; }
        100% { opacity: 1; }
    }
    
    .image1 { animation-delay: 0s; }
    .image2 { animation-delay: 5s; }
    .image3 { animation-delay: 10s; }
    /* Ajusta los retrasos según sea necesario */

    /* Donaciones.css */

/* style.css */
.general-layout .container {
    position: relative;
    width: 100%;
    min-height: 100vh;
    padding: 0 100px;
    background: #111;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  /* donaciones.css //color letras #d4973c // color fondo #231602 */
  .donaciones-layout .donaciones-container {
    background: #231602;
    /* estilos específicos para donaciones */
  }
  .credits {
    font-style: italic;
    color: #666;
    text-align: center;
    font-size: xx-small;
  }
  
  .aura-aclaracion {
    font-style: italic;
    color: #666;
    text-align: center;
    font-size: medium;
  }
  

   
  .contacto-dona {
    display: inline-block;
    padding: 10px 20px;
    font-style: italic;
    font-size: medium;
    color: #fff;
    background-color: #666; /* Color de fondo del botón */
    border: none;
    border-radius: 5px; /* Bordes redondeados */
    text-align: center;
    text-decoration: none; /* Elimina el subrayado */
    transition: background-color 0.3s, transform 0.3s; /* Transiciones suaves */
    cursor: pointer;
    margin-left: 46%;
  }

  @media (max-width: 480px) {
    .contacto-dona {
      font-size: 0.9em;
      padding: 8px 5px;
      width: 100%; /* Los botones ocuparán todo el ancho disponible */
      text-align: center; /* Centrar el texto en el botón */
      margin-left: 5px;
    }
  }
  
  .contacto-dona:hover {
    background-color: #555; /* Color de fondo al pasar el cursor */
    transform: translateY(-2px); /* Efecto de elevación al pasar el cursor */
  }
  
  .contacto-dona:active {
    background-color: #444; /* Color de fondo al hacer clic */
    transform: translateY(0); /* Sin elevación al hacer clic */
  }
  




/* Estilo base */
body, html {
    height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
  }
  

  .content-description {
    padding: 20px;
    flex-grow: 1;
  }
  
  .link-descargas {
    display: flex;
    gap: 7px; /* Espacio entre botones */
    margin-top: 190px;
  }
  
  .link-descargas a {
    display: inline-block;
    text-decoration: none;
    color: white;
    background-color: #007bff; /* Color de fondo del botón */
    padding: 10px 20px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    z-index: 99999;
  }
  
  .link-descargas a:hover {
    background-color: #0056b3; /* Color de fondo del botón al pasar el mouse */
  }


    /* Adaptación para pantallas grandes aca quede link descargas esta englobando muchas cosas*/
    @media (min-width: 1200px) {
        .link-descargas{
          font-size: 0.7em;
          padding: 15px 30px;
          margin-top: 200px; /*acca*/
          right: 10px;
          z-index: 99999;
        }
      }
  
  /* Adaptación para pantallas grandes */
  @media (min-width: 1200px) {
    .main-container{
      font-size: 0.8em; /*acca*/
      padding: 15px 30px; 
      margin-top: 250px; /*acca*/
      margin-left: -80px;
      z-index: 99999;
    }
  }
  
  
  /* Adaptación para pantallas pequeñas */
  @media (max-width: 767px) and (min-width: 481px) {
    .link-descargas a {
      font-size: 1em;
      padding: 10px 20px;
      width: 100%; /* Los botones ocuparán todo el ancho disponible */
      text-align: center; /* Centrar el texto en el botón */
      z-index: 99999;
    }
  }
  
  /* Adaptación para pantallas extra pequeñas CELU */ 
  @media (max-width: 480px) {
    .link-descargas a {
      font-size: 0.9em;
      padding: 8px 15px;
      width: 100%; /* Los botones ocuparán todo el ancho disponible */
      text-align: center; /* Centrar el texto en el botón */
      z-index: 99999;
    }
  }
  

  @media (max-width: 480px) {
    .social-logo {
      display: flex;
      align-items:flex-end;
      font-size: 0.9em;
      padding: 110px 35px;
      width: 100%; /* Los botones ocuparán todo el ancho disponible */
      z-index: 99999;
    }
    
    .social-logo li {
      list-style: none; /* Elimina los puntos de la lista */
      flex: 1;
      padding: 5px;
    }
  }